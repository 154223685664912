<template>
	<div class="config">
		<div class="head" style="display: flex;align-items: center;">
			<div class="title">{{ $route.meta.title }}</div>
			<el-button size="small" class="btn-blue" @click="handleBack">返回</el-button>
		</div>
		<div class="tabs" v-loading="loading">
			<el-tabs v-if="!loading" v-model="activeName" type="card" :before-leave="beforeLeave">
				<el-tab-pane label="企业信息配置" name="1"><company-info :detail="detail" /></el-tab-pane>
				<el-tab-pane label="自建应用配置" name="2">
					<keep-alive><company-app :detail="detail" v-if="activeName == 2" /></keep-alive>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import CompanyInfo from './Info';
import CompanyApp from './SelfApp';
import { reqGetConfigInfo } from '@/api/index';
export default {
	components: {
		CompanyInfo,
		CompanyApp
	},
	data() {
		return {
			activeName: '1',
			loading: false,
			detail: {}
		};
	},
	created() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			this.loading = true;
			reqGetConfigInfo({
				id: this.$route.query.id
			}).then(res => {
				this.detail = res.data;
				this.loading = false;
			});
		},
		handleBack() {
			this.$router.push('/company_setting/index');
		},
		beforeLeave(activeName, oldActiveName) {
			if (!this.detail.wxk_id || !this.detail.wxk_address_book_secret || !this.detail.wxk_customer_admin_secret) {
				this.$message.warning('请完成企业配置后进行操作');
				return false;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.config {
	width: 100%;
	position: relative;

	> div + div {
		margin-top: 15px;
	}
}
</style>
