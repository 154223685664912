<template>
	<div class="company-info" v-loading="loading" :element-loading-text="loadingText">
		<el-form v-if="!loading" size="small" label-position="left" label-width="240px" ref="form" :rules="rules" :model="form">
			<div class="config-title">
				<span class="icon"></span>
				<span class="name">企业微信配置</span>
			</div>
			<div v-if="state == 1" class="custom-message">数据同步成功，置灰部分无法修改；若需修改未置灰部分配置，请保证与企业微信后台配置一致，否则将影响功能使用</div>
			<div v-else-if="state == 0" class="custom-message">当前未配置或配置信息有误，数据无法同步，请先完成配置</div>
			<el-form-item label="企业简称" prop="wxk_name"><el-input v-model="form.wxk_name"></el-input></el-form-item>
			<el-form-item label="企业ID" prop="wxk_id"><el-input :disabled="state == 1" v-model="form.wxk_id" @blur="handleBlur(6)"></el-input></el-form-item>
			<el-form-item label="通讯录Secret" prop="wxk_address_book_secret">
				<el-input v-model="form.wxk_address_book_secret"></el-input>
				<i class="ri-question-line" @click="handleHelp(2)"></i>
			</el-form-item>
			<el-form-item label="客户管理Secret" prop="wxk_customer_admin_secret">
				<el-input v-model="form.wxk_customer_admin_secret"></el-input>
				<i class="ri-question-line" @click="handleHelp(3)"></i>
			</el-form-item>
			<el-form-item label="客户管理回调URL" prop="wxk_customer_callback_url">
				<el-input disabled style="width: calc(60% - 90px);" v-model="form.wxk_customer_callback_url"></el-input>
				<el-button size="small" class="btn-blue" @click="$copy($event, form.wxk_customer_callback_url)">一键复制</el-button>
				<i class="ri-question-line" @click="handleHelp(4)"></i>
			</el-form-item>
			<el-form-item label="客户管理回调Token" prop="wxk_customer_callback_token">
				<el-input style="width: calc(60% - 180px);" v-model="form.wxk_customer_callback_token" @blur="handleBlur(4)"></el-input>
				<el-button size="small" class="btn-white" @click="getConfig(1)">随机生成</el-button>
				<el-button size="small" class="btn-blue" @click="$copy($event, form.wxk_customer_callback_token)">一键复制</el-button>
				<i class="ri-question-line" @click="handleHelp(5)"></i>
			</el-form-item>
			<el-form-item label="客户管理回调EncodingAESKey" prop="wxk_customer_callback_key">
				<el-input style="width: calc(60% - 180px);" v-model="form.wxk_customer_callback_key" @blur="handleBlur(5)"></el-input>
				<el-button size="small" class="btn-white" @click="getConfig(2)">随机生成</el-button>
				<el-button size="small" class="btn-blue" @click="$copy($event, form.wxk_customer_callback_key)">一键复制</el-button>
				<i class="ri-question-line" @click="handleHelp(6)"></i>
			</el-form-item>
			<div class="config-title" style="margin-top: 15px;">
				<span class="icon"></span>
				<span class="name">公众号配置</span>
			</div>
			<div class="custom-message">公众号配置用于【聊天工具栏】-【聊天资料】中，文章预览与阅读中阅读统计功能使用</div>
			<el-form-item label="Appid" prop="wxk_public_app_id">
				<el-input v-model="form.wxk_public_app_id"></el-input>
				<i class="ri-question-line" @click="handleHelp(7)"></i>
			</el-form-item>
			<el-form-item label="AppSecret" prop="wxk_public_app_secret">
				<el-input v-model="form.wxk_public_app_secret"></el-input>
				<i class="ri-question-line" @click="handleHelp(8)"></i>
			</el-form-item>
			<el-form-item label="域名校验">
				<el-upload
					class="upload"
					style="width: 500px;"
					:headers="$uploadParams('upload_domain_verification_file').headers"
					:action="reqUploadFile"
					:data="$uploadParams('upload_domain_verification_file').query"
					name="domain_verification_file"
					multiple
					:limit="1"
					:before-upload="beforeUploadFile"
				>
					<el-button style="margin-left: 0px" class="btn-blue" icon="el-icon-upload" size="small">上传校验文件</el-button>
				</el-upload>
			</el-form-item>
			<el-form-item style="margin-top: 15px;"><el-button size="small" class="btn-blue" @click="handleSave">保存配置</el-button></el-form-item>
		</el-form>
	</div>
</template>

<script>
import { reqGetConfigRandom, reqAddWechat, reqSyncConfig, reqDelConfig, reqUploadFile } from '@/api/index';
export default {
	props: {
		detail: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			form: {
				wxk_name: '',
				wxk_id: '',
				wxk_address_book_secret: '',
				wxk_customer_admin_secret: '',
				wxk_customer_callback_url: '',
				wxk_customer_callback_token: '',
				wxk_customer_callback_key: '',
				wxk_public_app_id: '',
				wxk_public_app_secret: ''
			},
			rules: {
				wxk_name: [
					{
						required: true,
						message: '请输入企业简称',
						trigger: 'blur'
					}
				],
				wxk_id: [
					{
						required: true,
						message: '请输入企业ID',
						trigger: 'blur'
					}
				],
				wxk_address_book_secret: [
					{
						required: true,
						message: '请输入通讯录Secret',
						trigger: 'blur'
					}
				],
				wxk_customer_admin_secret: [
					{
						required: true,
						message: '请输入客户管理Secret',
						trigger: 'blur'
					}
				],
				wxk_customer_callback_token: [
					{
						required: true,
						message: '请输入客户管理回调Token',
						trigger: 'blur'
					}
				],
				wxk_customer_callback_key: [
					{
						required: true,
						message: '请输入客户管理回调EncodongAESKey',
						trigger: 'blur'
					}
				],
				wxk_customer_callback_url: [
					{
						required: true,
						message: '请输入客户管理回调URL',
						trigger: 'blur'
					}
				]
			},
			is_check: false,
			id: '',
			loading: false,
			loadingText: '',
			reqUploadFile,
			state: 0
		};
	},
	mounted() {
		this.getForm(this.detail);
	},
	methods: {
		getForm(row) {
			this.id = row.id;
			const {
				wxk_name,
				wxk_id,
				wxk_address_book_secret,
				wxk_customer_admin_secret,
				wxk_customer_callback_url,
				wxk_customer_callback_token,
				wxk_customer_callback_key,
				wxk_public_app_id,
				wxk_public_app_secret
			} = row;
			if (Boolean(wxk_id) && Boolean(wxk_address_book_secret) && Boolean(wxk_customer_admin_secret)) {
				this.state = 1;
				this.getConfig(7);
			} else {
				this.state = 0;
				this.getConfig();
			}
			this.form = {
				wxk_name,
				wxk_id,
				wxk_address_book_secret,
				wxk_customer_admin_secret,
				wxk_customer_callback_url,
				wxk_customer_callback_token,
				wxk_customer_callback_key,
				wxk_public_app_id,
				wxk_public_app_secret
			};
		},
		// 获取配置信息
		getConfig(type = 3) {
			reqGetConfigRandom({
				id: this.id,
				type
			}).then(res => {
				if (type == 1) {
					this.form.wxk_customer_callback_token = res.data.token;
				} else if (type == 2) {
					this.form.wxk_customer_callback_key = res.data.aes_key;
				} else if (type == 3) {
					this.form.wxk_customer_callback_token = res.data.token;
					this.form.wxk_customer_callback_url = res.data.path;
					this.form.wxk_customer_callback_key = res.data.aes_key;
				} else if (type == 7) {
					this.form.wxk_customer_callback_url = res.data.path;
				}
			});
		},
		// 失焦检测
		handleBlur(type) {
			let params = {
				id: this.id,
				type
			};
			if (type == 4) {
				params.wxk_customer_callback_token = this.form.wxk_customer_callback_token;
			} else if (type == 5) {
				params.wxk_customer_callback_key = this.form.wxk_customer_callback_key;
			} else if (type == 6) {
				params.wxk_id = this.form.wxk_id;
			}
			reqGetConfigRandom(params);
		},
		reqSync() {
			this.loading = true;
			this.loadingText = '正在进行企业微信同步，请耐心等待';
			reqSyncConfig({
				wxk_id: this.form.wxk_id
			}).then(async res => {
				if (res.code == 200) {
					await this.$store.dispatch('setting/getWxkConfig');
					this.loadingText = '企业微信同步成功，即将跳转';
					setTimeout(() => {
						this.loading = false;
						this.$router.push('/company_setting/index');
					}, 1500);
				} else {
					this.loading = false;
					reqDelConfig({
						id: this.id
					});
					this.$message.error('企业微信同步失败，请检查配置');
				}
			});
		},
		// 保存配置
		handleSave() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					const { wxk_name, wxk_id, wxk_address_book_secret, wxk_customer_admin_secret, wxk_public_app_id, wxk_public_app_secret } = this.form;
					reqAddWechat({
						id: this.id,
						wxk_name,
						wxk_id,
						wxk_address_book_secret,
						wxk_customer_admin_secret,
						wxk_public_app_id,
						wxk_public_app_secret
					}).then(res => {
						this.reqSync();
					});
				} else {
					return false;
				}
			});
		},
		beforeUploadFile(file) {
			let types = ['text/plain'];
			const isText = types.includes(file.type);
			const isLtSize = file.size / 1024 / 1024 < 20;
			if (!isText) {
				this.$message.error('上传文件只能是txt格式!');
				return false;
			}
			if (!isLtSize) {
				this.$message.error('上传文件大小不能超过 20MB!');
				return false;
			}
			return isText && isLtSize;
		},
		// 帮助
		handleHelp(domIndex) {
			this.$bus.$emit('showHelp', domIndex);
		}
	}
};
</script>

<style lang="less" scoped>
.company-info {
	position: relative;
	width: 100%;
	height: calc(100vh - 240px);
	background: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;

	/* 表单间距控制 */
	/deep/.el-form-item__label {
		line-height: 40px;
	}

	.el-form {
		margin-right: 10px;

		.config-title {
			width: calc(100% - 24px);
			height: 32px;
			background: #e5ebfb;
			border-radius: 5px 5px 0px 0px;
			display: flex;
			align-items: center;
			padding: 0px 12px;
			margin-bottom: 15px;

			.icon {
				width: 3px;
				height: 16px;
				background: #6881ec;
				border-radius: 2px;
				margin-right: 6px;
			}

			.name {
				font-size: 14px;
				font-weight: bold;
				color: #6881ec;
			}
		}

		.el-input {
			width: 60%;
			margin-right: 10px;
		}

		.el-button {
			margin-right: 10px;
		}

		.el-button + .el-button {
			margin-left: 0px;
		}

		i {
			vertical-align: middle;
			color: #666;
			font-size: 20px;
			cursor: pointer;
		}
	}

	.custom-message {
		margin-bottom: 15px;
	}
}
</style>
