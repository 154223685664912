<template>
	<div class="self-app">
		<div class="main">
			<div class="left-list">
				<div class="scroll">
					<div style="margin-bottom: 10px;font-size: 14px;color: #6881EC;display: flex;align-items: center;">
						<span>自建应用</span>
						<i class="ri-question-line" @click="showCourse = !showCourse" style="margin-left: 5px;cursor: pointer;font-size: 16px"></i>
					</div>
					<div class="custom-message" style="margin-bottom: 15px;width: calc(100% - 36px);" v-show="showCourse">
						1、自建应用支持多个应用配置，可管理多个应用下的功能配置
						<br />
						2、当添加/切换应用为当前应用时，请检查应用下的配置是否已完成，否则将影响功能使用
						<br />
					</div>
					<div :class="item.is_in_use == 1 ? 'item pick-list' : 'item'" v-for="(item, index) in appList" :key="index">
						<p v-if="item.is_in_use == 1" class="tip-check">当前应用</p>
						<p v-else class="tip-default" @click="handleToggleApp(item)">选择应用</p>
						<img :src="item.logo" width="50px" height="50px" alt="logo" />
						<span class="line">
							<span></span>
							<span></span>
						</span>
						<span class="name" :title="item.name">{{ item.name }}</span>
					</div>
					<div
						class="item add"
						@click="
							() => {
								addVisible = true;
							}
						"
					>
						<i class="el-icon-plus"></i>
						<span>添加应用</span>
					</div>
				</div>
			</div>
			<div class="right-content">
				<div class="scroll">
					<div class="config-title">
						<span class="icon"></span>
						<span class="name">应用信息</span>
					</div>
					<div style="margin-bottom: 10px;" class="custom-message">
						1、请勿在企业微信后台随意删除应用，删除应用后，企业微信端相关功能将无法正常使用，请谨慎操作
						<br />
					</div>
					<div class="config-wrap">
						<el-form label-position="left" label-width="150px" size="small" ref="configForm" :model="config">
							<el-form-item label="自建应用AgentId"><el-input style="width: 595px;" disabled v-model="config.app_agent_id"></el-input></el-form-item>
							<el-form-item label="自建应用Secret"><el-input style="width: 595px;" disabled v-model="config.app_secret"></el-input></el-form-item>
						</el-form>
					</div>
					<div class="config-title" style="margin-top: 15px;">
						<span class="icon"></span>
						<span class="name">页面配置</span>
					</div>
					<div style="margin-bottom: 10px;" class="custom-message">
						1、配置当前应用工具栏中的功能页面
						<br />
						2、切换应用后需重新进行页面地址配置
						<br />
					</div>
					<div class="config-wrap">
						<el-form label-position="left" label-width="150px" size="small" ref="configForm" :model="config">
							<el-form-item label="应用主页地址">
								<el-input disabled v-model="config.corp_app_home"></el-input>
								<el-button class="btn-blue" @click="$copy($event, config.corp_app_home)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(12)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="聊天资料地址">
								<el-input disabled v-model="config.chat_app_home_url"></el-input>
								<el-button class="btn-blue" @click="$copy($event, config.chat_app_home_url)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(13)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="客户详情地址">
								<el-input disabled v-model="config.corp_app_customer"></el-input>
								<el-button class="btn-blue" @click="$copy($event, config.corp_app_customer)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(14)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="营销活动地址">
								<el-input disabled v-model="config.marketing_active_url"></el-input>
								<el-button class="btn-blue" @click="$copy($event, config.marketing_active_url)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(15)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="推送任务地址">
								<el-input disabled v-model="config.sop_task_url"></el-input>
								<el-button class="btn-blue" @click="$copy($event, config.sop_task_url)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(16)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
						</el-form>
					</div>
					<div class="config-title" style="margin-top: 15px;">
						<span class="icon"></span>
						<span class="name">应用配置</span>
					</div>
					<div style="margin-bottom: 10px;" class="custom-message">
						1、切换应用后需重新进行配置
						<br />
					</div>
					<div class="config-wrap">
						<el-form label-position="left" label-width="150px" ref="configForm" :model="config">
							<el-form-item label="授权登录回调域">
								<el-input disabled size="small" v-model="href"></el-input>
								<el-button size="small" class="btn-blue" @click="$copy($event, href)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(18)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="可信域名配置">
								<el-input disabled size="small" v-model="href"></el-input>
								<el-button size="small" class="btn-blue" @click="$copy($event, href)">一键复制</el-button>
								<i
									class="ri-question-line"
									@click="handleHelp(17)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
							<el-form-item label="域名校验">
								<el-upload
									:disabled="appList.length == 0"
									class="upload"
									:headers="$uploadParams('upload_domain_verification_file').headers"
									style="width: 500px;"
									:action="reqUploadFile"
									:data="$uploadParams('upload_domain_verification_file').query"
									name="domain_verification_file"
									multiple
									:limit="1"
									:before-upload="beforeUploadFile"
								>
									<el-button :disabled="appList.length == 0" style="margin-left: 0px" class="btn-blue" icon="el-icon-upload" size="small">上传校验文件</el-button>
								</el-upload>
							</el-form-item>
							<el-form-item label="可调应用配置">
								<span style="vertical-align: middle">若未配置此项将导致聊天资料发送失败；已配置请勿略</span>
								<i
									class="ri-question-line"
									@click="handleHelp(19)"
									style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"
								></i>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<!-- 添加自建应用 -->
		<el-dialog title="添加应用" :close-on-click-modal="false" :visible.sync="addVisible" width="800px" :before-close="addClose">
			<div class="course" style="margin: 0;">
				<p><span class="black">1、使用爱多客系统需提前在企业微信后台创建应用，再通过填写应用参数的方式将应用同步过来</span></p>
				<p><span class="black">2、若同时添加多个应用，每切换应用为当前应用时，相关页面的参数配置需重新配置，且应用中的数据将重新同步</span></p>
				<p><span class="black">2、应用一旦在系统创建，无法删除，请谨慎操作</span></p>
			</div>
			<el-form style="margin-top: 10px;" label-position="left" label-width="150px" :model="add" ref="addForm">
				<el-form-item label="自建应用AgentId" prop="app_agent_id" :rules="[{ required: true, message: '请输入自建应用AgentId', trigger: 'blur' }]">
					<el-input style="width: calc(100% - 30px);" size="small" v-model="add.app_agent_id" placeholder="请输入"></el-input>
					<i class="ri-question-line" @click="handleHelp(10)" style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"></i>
				</el-form-item>
				<el-form-item label="自建应用Secret" prop="app_secret" :rules="[{ required: true, message: '请输入自建应用Secret', trigger: 'blur' }]">
					<el-input style="width: calc(100% - 30px);" size="small" v-model="add.app_secret" placeholder="请输入"></el-input>
					<i class="ri-question-line" @click="handleHelp(11)" style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"></i>
				</el-form-item>
				<el-form-item label="授权登录回调域">
					<el-input disabled style="width: calc(100% - 125px);margin-right: 15px;" size="small" v-model="href"></el-input>
					<el-button size="small" class="btn-blue" @click="$copy($event, '内容')">一键复制</el-button>
					<i class="ri-question-line" @click="handleHelp(18)" style="margin-left: 10px; cursor: pointer;font-size: 20px;vertical-align: middle; color: #666;"></i>
				</el-form-item>
				<el-form-item label="下载应用Logo">
					<div class="download">
						<img src="../../assets/images/logo.png" width="75" alt="" />
						<el-button
							size="small"
							class="btn-blue"
							@click="
								() => {
									downloadIamge();
								}
							"
						>
							点击下载
						</el-button>
					</div>
				</el-form-item>
				<el-form-item label="设为当前应用">
					<el-checkbox style="vertical-align: middle;" :true-label="1" :false-label="0" v-model="add.is_use_app"></el-checkbox>
					<el-tooltip class="item" effect="dark" content="当已设置当前应用时,勾选此项将切换目标添加的应用为当前应用" placement="top-start">
						<i style="margin-left: 10px;vertical-align: middle;font-size: 20px;color: #666;" class="ri-question-line"></i>
					</el-tooltip>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" class="btn-white" @click="addClose">取 消</el-button>
				<el-button size="small" class="btn-blue" @click="handleAddSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetAppList, reqAddWxkApp, reqReplaceApp, reqAppDetail, reqUploadFile } from '@/api/index';
export default {
	props: ['detail'],
	data() {
		return {
			config: {
				name: ''
			},
			appList: [],
			// 添加应用
			addVisible: false,
			add: {
				app_agent_id: '',
				app_secret: '',
				is_use_app: 0
			},
			activeId: '',
			reqUploadFile,
			href: window.location.host,
			show1: false,
			show2: false,
			showCourse: false,
			wxk_id: ''
		};
	},
	watch: {
		appList: {
			handler(val) {
				val.forEach(item => {
					if (item.is_in_use == 1) {
						this.activeId = item.id;
						reqAppDetail({
							id: this.activeId,
							wxk_id: this.wxk_id
						}).then(res => {
							this.config = res.data;
						});
					}
				});
			},
			deep: true
		}
	},
	mounted() {
		this.wxk_id = this.detail.wxk_id;
		this.getAppList();
	},
	methods: {
		getAppList() {
			this.appList = [];
			reqGetAppList({
				wxk_id: this.wxk_id
			}).then(res => {
				if (res.data.length != 0) {
					this.appList = res.data;
					this.activeId = res.data[0].id;
				} else {
					this.appList = res.data;
					this.activeId = '';
				}
			});
		},
		addClose() {
			this.$refs.addForm.resetFields();
			this.addVisible = false;
		},
		handleAddSubmit() {
			this.$refs['addForm'].validate(valid => {
				if (valid) {
					reqAddWxkApp({
						app_agent_id: this.add.app_agent_id,
						app_secret: this.add.app_secret,
						is_use_app: this.add.is_use_app,
						wxk_id: this.wxk_id
					}).then(res => {
						this.$message.success(res.msg);
						this.addVisible = false;
						this.getAppList();
					});
				} else {
					return false;
				}
			});
		},
		// 图片下载
		downloadIamge() {
			var alink = document.createElement('a');
			alink.href = '/logo.png';
			alink.download = '自建应用Logo'; //图片名
			alink.click();
		},
		handleToggleApp(data) {
			this.$confirm(`确定将【${data.name}】切换为当前应用，切换后相关数据将重新同步，应用相关页面将重新配置`, '切换应用', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					reqReplaceApp({
						id: data.id,
						wxk_id: this.wxk_id
					}).then(res => {
						this.$message.success(res.msg);
						this.getAppList();
					});
				})
				.catch(() => {});
		},
		beforeUploadFile(file) {
			let types = ['text/plain'];
			const isText = types.includes(file.type);
			const isLtSize = file.size / 1024 / 1024 < 20;
			if (!isText) {
				this.$message.error('上传文件只能是txt格式!');
				return false;
			}
			if (!isLtSize) {
				this.$message.error('上传文件大小不能超过 20MB!');
				return false;
			}
			return isText && isLtSize;
		},
		// 帮助
		handleHelp(domIndex) {
			this.$bus.$emit('showHelp', domIndex);
		}
	}
};
</script>

<style lang="less" scoped>
.self-app {
	position: relative;
	width: 100%;

	.main {
		display: flex;

		.left-list {
			width: 240px;
			height: calc(100vh - 272px);
			border-radius: 6px;
			border: 1px solid #b9c5e9;
			padding: 15px 5px 15px 15px;

			.scroll {
				height: calc(100vh - 272px);
				overflow-x: hidden;
				overflow-y: auto;

				.item {
					position: relative;
					display: flex;
					align-items: center;
					padding: 25px 15px;
					border: 2px solid #b9c5e9;
					border-radius: 5px;
					justify-content: space-between;
					margin-right: 10px;

					.tip-check {
						position: absolute;
						width: 80px;
						height: 25px;
						background-color: #5c84ec;
						text-align: center;
						line-height: 25px;
						font-size: 14px;
						top: -2px;
						right: -2px;
						color: #fff;
						border-radius: 0 5px 0 5px;
					}

					.tip-default {
						position: absolute;
						width: 80px;
						height: 25px;
						background-color: #fff;
						border: 2px solid #b9c5e9;
						text-align: center;
						line-height: 25px;
						font-size: 14px;
						top: -2px;
						right: -2px;
						color: #5c84ec;
						border-radius: 0 5px 0 5px;
						cursor: pointer;
					}

					.line {
						display: flex;
						flex-direction: column;

						> span {
							width: 1px;
							background-color: #e8e7e7;
						}

						> span:nth-child(1) {
							height: 20px;
						}

						> span:nth-child(2) {
							margin-top: 10px;
							height: 10px;
						}
					}

					.name {
						width: 100px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.add {
					display: flex;
					justify-content: center;
					cursor: pointer;
					padding: 35px 0;

					i {
						font-size: 30px;
						color: #5c84ec;
					}

					span {
						font-size: 16px;
						color: #333;
					}
				}

				.item + .item {
					margin-top: 15px;
				}

				.pick-list {
					border: 2px solid #5c84ec;
				}
			}
		}

		.right-content {
			flex-grow: 1;
			height: calc(100vh - 272px);
			border-radius: 6px;
			border: 1px solid #b9c5e9;
			padding: 15px;
			margin-left: 15px;

			.scroll {
				height: calc(100vh - 272px);
				overflow-x: hidden;
				overflow-y: auto;
			}

			.el-form {
				/* 表单间距控制 */
				/deep/.el-form-item__label {
					line-height: 40px;
				}
			}

			.config-title {
				width: calc(100% - 24px);
				height: 32px;
				background: #e5ebfb;
				border-radius: 5px 5px 0px 0px;
				display: flex;
				align-items: center;
				padding: 0px 12px;
				margin-bottom: 15px;

				.icon {
					width: 3px;
					height: 16px;
					background: #6881ec;
					border-radius: 2px;
					margin-right: 6px;
				}

				.name {
					font-size: 14px;
					font-weight: bold;
					color: #6881ec;
				}
			}

			.config-wrap {
				.el-input {
					width: 500px;
					margin-right: 15px;
				}
			}

			.line {
				width: 100%;
				height: 2px;
				background-color: #5c84ec;
				margin: 25px auto;
			}
		}
	}

	.download {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 10px;

		img {
			margin-bottom: 15px;
		}

		.el-button {
			margin-left: 0px;
		}
	}
}
</style>
